/* APP DESKTOP */

/* html,
body {
    height: 100% !important;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

#root {
    height: 100%;
} */

.icon-small {
    display: block;
    margin: auto;
    width: 15px;
    height: 15px;
    transition: all 0.2s;
    transform-origin: 50% 50%;
}

.icon-small:hover {
    transform: scale(1.2);
}

.nav-right-text {
    font-size: 14px;
    color: #5a6571;
    white-space: nowrap;
}

.first-nav {
    border-bottom: 1px solid #D4D4D5;
}

.first-nav-right {
    transition: all 0.2s;
    transform-origin: 50% 50%;
}

.first-nav-right:hover {
    transform: scale(1.2);
    font-weight: bold;
}

.second-nav {
    border-bottom: 1px solid #D4D4D5;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    color: #5a6571;
    z-index: 10;
}

.second-nav>.navbar-collapse>.navbar-nav>.nav-item {
    height: 100%;

}

.second-nav>.navbar-collapse>.navbar-nav>.nav-item>.nav-link {
    color: #5a6571 !important;
}

.second-nav>.navbar-collapse>.navbar-nav>.nav-item:hover {
    font-weight: bold;
    border-bottom: 1px solid #86868b;
}

.nav-item-active {
    font-weight: bold;
    border-bottom: 1px solid #86868b;
    height: 100%;
    align-items: center;
    display: flex;
    color: #C6B39A !important;
}

.nav-item-active a {
    font-weight: bold;
    color: #C6B39A !important;
}

.service-card {
    max-width: 340px;
    box-shadow: 1px 1px 10px 5px #b5b5b5;
    border-radius: 4px;
}

.place-card {
    margin: 10px;
    max-width: 340px;
    box-shadow: 1px 1px 15px 5px #b5b5b5;
    border: 8px solid white;
    border-radius: 4px;
}

.place-card-img {
    /* width: 299px !important;*/
    height: 225px !important;
    /* box-shadow: 0.5rem .5rem 0.8rem rgba(0, 0, 0, .40); */
    width: 100%;
    height: 100%;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    border-radius: 4px;
    transition: all 0.5s;
}

.place-card-img:hover {
    /* transition: all 0.5s;
    box-shadow: none; */
}

.place-card-img-shadow {
    background: transparent linear-gradient(180deg, #00000000 70%, #000000 100%) 0% 0% no-repeat padding-box;
    height: 100%;
    border-radius: 4px;
    transition: all 0.5s;
}

.place-card-img-shadow:hover {
    transition: all 0.5s;
    background: linear-gradient(180deg, transparent 70%, transparent 100%) 0% 0% no-repeat padding-box;
}

.circle-categories {
    width: 34px;
    height: 34px;
    min-width: 34px;
    min-height: 34px;
    border-radius: 50%;
    background-position-x: center;
    background-position-y: center;
    /* border: 1px solid; */
    background-size: cover;
}

.home-text {
    /* color: #C5B39A; */
    /* font-size: 28px; */
    font-size: 21px;
    font-weight: 400;
}

.category-group {
    font-size: 17px;
    transition: all 0.5s;
    justify-content: center !important;
}

.category-group:hover {
    transition: all 0.5s;
    transform: translateY(-5px);
    font-weight: 800 !important;
    color: black;
    cursor: pointer;
}

.places-container {
    /* overflow-y: scroll;
    height: 68.5vh; */
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

}

.carousel-item-height {
    height: 50vh;
}

/*@media (min-width: 600px){
    .places-container {
        grid-template-columns: repeat(2, 1fr); 
    }
}

@media (min-width: 900px) {
    .places-container { grid-template-columns: repeat(3, 1fr); }
}*/


.places-container::-webkit-scrollbar {
    display: block !important;
}

.places-container::-webkit-scrollbar-track {

    border-radius: 10px !important;
    background-color: #F5F5F5;
}

.places-container::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.places-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #D8D8D8;
}

.scroll::-webkit-scrollbar {
    display: block !important;
}

.scroll::-webkit-scrollbar-track {
    border-radius: 10px !important;
    background-color: #F5F5F5;
}

.scroll::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #D8D8D8;
}

.place-fav {
    width: 43px;
    height: 26px;
    background-color: white;
    border-radius: 5px;
}

.place-text {
    font-size: 18px;
    font-weight: bold;
    width: 291px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.place-distance {
    color: white;
    font-size: 18px;
    font-weight: bold;
}

.place-distance>span {
    font-size: 12px;
}

.price {
    font-weight: bold;
    text-shadow: 0px 2px 6px #000000CC;
    color: white;
}

.services-container {
    padding: 15px;
    border-radius: 15px;
    background-color: #F5F5F5;
}

.fa-heart-desk {
    background-image: url("/src/components/Mobile/icons/heart.svg");
    background-repeat: no-repeat;
    background-size: auto;
    background-position-y: center;
    height: 100%;
    background-position-x: center;
}

.navbar-li-container {
    height: 81px;
}

.places-submenu:hover {
    cursor: pointer;
}

.hotel-info-cover {
    height: 350px;
    background-position: center;
    background-size: cover;
}

.hotel-info-main {
    padding: 10px;
}

.hotel-info-container {
    box-shadow: 4px 8px 20px rgb(0 0 0 / 10%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    margin-top: 2em;
    border-radius: 10px;
}

.arrow-right {
    background-color: white;
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    right: 14px;
    top: 42px;
    box-shadow: 4px 8px 20px rgb(0 0 0 / 10%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.arrow-left {
    background-color: white;
    position: absolute;
    left: 14px;
    top: 42px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    box-shadow: 4px 8px 20px rgb(0 0 0 / 10%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.arrow-right>img {
    position: relative;
    left: 4px;
    top: -2px;
}

.arrow-left>img {
    position: relative;
    left: 4px;
    top: -2px;
}

.nav-link {
    color: #5a6571 !important;
}

.react-multiple-carousel__arrow--right {
    right: calc(0.1% + 1px) !important;
}

.react-multiple-carousel__arrow--left {
    left: calc(0.1% + 1px) !important;
}

.cat-scroll::-webkit-scrollbar {
    display: block !important;
}

.cat-scroll::-webkit-scrollbar-track {
    border-radius: 10px !important;
    background-color: #F5F5F5;
}

.cat-scroll::-webkit-scrollbar {
    height: 8px;
    background-color: #F5F5F5;
}

.cat-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #D8D8D8;
}

#servizi_desktop::-webkit-scrollbar {
    height: 10px;
    display: block !important;
    border-radius: 10px;
}

/* Track */
#servizi_desktop::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 20px;
}

/* Handle */
#servizi_desktop::-webkit-scrollbar-thumb {
    background: #c6b39a;
    border-radius: 20px;
}

/* Handle on hover */
#servizi_desktop::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.box-servizio {
    height: 225px !important;
    width: 100%;
    height: 100%;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    border-radius: 4px;
    transition: all 0.5s;
    box-shadow: inset 0px -50px 50px -30px rgba(0,0,0,0.80);
}