/* APP MOBILE */

.place-card-img-mobile {
    /* width: 299px !important;*/
    height: 225px !important;
    box-shadow: inset 0.5rem .5rem 0.8rem rgba(0,0,0,.40);
    width:100%;
    height: 100%;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    border-radius: 15px;
    transition: all 0.5s;
}

.place-card-img-mobile_v2 {
    height: 200px !important;
    box-shadow: inset 0.5rem .5rem 0.8rem rgba(0,0,0,.40);
    width:100%;
    height: 100%;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    border-radius: 20%;
    transition: all 0.5s;
}